import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import translations from '@/components/common/translations.vue.json'

import VueI18n from 'vue-i18n'

import PrismicVue from '@prismicio/vue'
import linkResolver from '@/prismic/link-resolver'
import htmlSerializer from '@/prismic/html-serializer'

import Loader from '@/components/common/Loader.vue'
import TableOfContents from '@/components/common/TableOfContents.vue'
import Notification from '@/components/common/Notification.vue'
import Container from '@/components/common/Container.vue'
import PageWrapper from '@/components/page/PageWrapper.vue'
import SliceChapter from '@/components/page/slices/SliceChapter.vue'
import SliceCustomHtml from '@/components/page/slices/SliceCustomHtml.vue'
import SlicePageIntro from '@/components/page/slices/SlicePageIntro.vue'
import MainNavBar from '@/components/MainNavBar.vue'
import AuthPageWrapper from '@/components/auth/AuthPageWrapper.vue'

Vue.component('Loader', Loader)
Vue.component('TableOfContents', TableOfContents)
Vue.component('Notification', Notification)
Vue.component('Container', Container)
Vue.component('PageWrapper', PageWrapper)
Vue.component('SliceChapter', SliceChapter)
Vue.component('SliceCustomHtml', SliceCustomHtml)
Vue.component('SlicePageIntro', SlicePageIntro)
Vue.component('MainNavBar', MainNavBar)
Vue.component('AuthPageWrapper', AuthPageWrapper)

Vue.config.productionTip = false

const trainingPortalPrismicEndpoint = 'https://training-portal.prismic.io/api/v2'

Vue.use(PrismicVue, {
  endpoint: trainingPortalPrismicEndpoint,
  linkResolver,
  htmlSerializer,
})

// We only use vue-i18n for easy compatibility with our main app.
Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'en',
  silentTranslationWarn: true,
  messages: translations,
  escapeParameterHtml: true,
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
