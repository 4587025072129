/**
 * To learn more about Link Resolving check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/link-resolving
 */

interface PrismicLinkResolver {
  id: string
  type: string
  tags: string[]
  lang: string
  uid: string
  link_type: string
  isBroken: boolean
}

export default function(doc: PrismicLinkResolver) {
  if(doc.isBroken) {
    return '/not-found'
  }

  if(doc.type === 'question_and_answer') {
    return '#' + doc.uid
  }

  return '/not-found'
}
