// A rather permissive regular expression that allow sTLDs (e.g. @apple).
const EMAIL_REX = /\w+([-+.']\w+)*@\w+([-.]\w+)*\w+([-.]\w+)*/

export function getUserFullName(firstName: string, lastName: string): string {
  return firstName.length > 0 || lastName.length > 0 ?
    `${firstName} ${lastName}` : ''
}

export const isEmail = function(email: string): boolean {
  return EMAIL_REX.test(email)
}

export const generateRandomString = function(): string {
  return Math.random().toString().substring(2)
}

export const createHtmlIdFromString = function(textString: string): string {
  const isString = typeof textString === 'string'
  if(!isString) {
    return ''
  } else {
    return textString
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with hyphens.
      .replace(/[^\w-]+/g, '') // Remove any non-alphanumeric characters except hyphens.
      .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen.
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeQueryString = function(obj: {[key: string]: any}): string {
  return Object
    .keys(obj)
    .filter(key => obj[key] !== undefined)
    .map(
      k => Array.isArray(obj[k]) ?
        (obj[k].length > 0 ?
          obj[k].map((arrItem: string | number | boolean) =>
            `${encodeURIComponent(k)}=${encodeURIComponent(arrItem)}`).join('&') :
          // In the case of an empty array leave the key intact (e.g. "myArr=").
          `${encodeURIComponent(k)}=`
        ) :
        `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&')
}
