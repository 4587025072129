import { BACKEND_BASE_URL } from '@/consts'

function getCookie(name: string): string|null {
  let cookieValue: string|null = null
  if(document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if(cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

type HttpMethod = 'POST'|'PUT'|'GET'|'PATCH'|'DELETE'
export function apiFetch<T>(
  apiResource: string,
  method: HttpMethod = 'GET',
  data?: Object,
): Promise<T> {
  const init: RequestInit = {
    method: method,
  }
  const csrfToken = getCookie('csrftoken')
  if(data !== undefined) {
    init.body = JSON.stringify(data)
    init.headers = {
      'Content-Type': 'application/json',
    }
  }
  if(method !== 'GET' && csrfToken !== null) {
    const csrfHeaderAdd = {'X-CSRFToken': csrfToken}
    if(init.headers === undefined) {
      init.headers = csrfHeaderAdd
    } else {
      init.headers = Object.assign({'X-CSRFToken': csrfToken}, init.headers)
    }
  }
  return fetch(`${BACKEND_BASE_URL}${apiResource}`, init).then(response => {
    return response.json() as Promise<T>
  })
}
