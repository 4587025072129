import { getUserFullName } from '@/utils/utils'
import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    websiteData: null,
    userData: null,
  },
  getters: {
    getUserData: (state) => {
      return state.userData
    },
    getUserFullName: (state) => {
      return state.userData !== null ?
        getUserFullName(state.userData.first_name, state.userData.last_name) :
        ''
    },
    getWebsiteData: (state) => {
      return state.websiteData
    },
  },
  mutations: {
    setUserData(state, payload: UserDataUpdate) {
      state.userData = payload.userData
    },
    setWebsiteData(state, payload: WebsiteData) {
      state.websiteData = payload
    },
  },
  actions: {
  },
  modules: {
  },
}


export default new Vuex.Store(store)
