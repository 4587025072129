import {
  apiFetch,
} from './api_utils'

/**
 * Mark training chapter as done/undone. Specifying action as "mark" will
 * mark chapter as done, while "clear" will mark chapter as undone.
 */
export async function markTrainingChapter(
  chapterId: number,
  action: 'mark'|'clear',
): Promise<void> {
  const data = {
    action: action,
  }
  return apiFetch<void>(`/training-chapters/${chapterId}/mark/`, 'POST', data)
}

export async function getUserData(): Promise<UserData> {
  return apiFetch<UserData>('/my-userinfo/')
}

export async function resetPasswordConfirm(resetPasswordConfirmData: PasswordResetConfirm): Promise<void> {
  return apiFetch<void>('/rest-auth/password/reset/confirm/', 'POST', resetPasswordConfirmData)
}

export async function resetPassword(resetPasswordData: PasswordResetData): Promise<void> {
  return apiFetch<void>('/rest-auth/password/reset/', 'POST', resetPasswordData)
}

export async function login(loginFormData: LoginFormData): Promise<LoginResponse> {
  return apiFetch<LoginResponse>('/rest-auth/login/', 'POST', loginFormData)
}

export async function logout(): Promise<void> {
  return apiFetch<void>('/rest-auth/logout/', 'POST')
}
