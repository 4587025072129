import { render, staticRenderFns } from "./SliceChapter.vue?vue&type=template&id=55842db6&"
import script from "./SliceChapter.vue?vue&type=script&lang=ts&"
export * from "./SliceChapter.vue?vue&type=script&lang=ts&"
import style0 from "./SliceChapter.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports