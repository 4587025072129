import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/components/auth/Login.vue'
import Logout from '@/components/auth/Logout.vue'
import PasswordResetConfirm from '@/components/auth/PasswordResetConfirm.vue'
import PasswordReset from '@/components/auth/PasswordReset.vue'
import NotFound from '@/views/NotFound.vue'
import Training from '@/views/Training.vue'
import Faq from '@/views/Faq.vue'
import QuickStart from '@/views/QuickStart.vue'
import KeyStepsAndSettings from '@/views/KeyStepsAndSettings.vue'
import HandsOnTraining from '@/views/HandsOnTraining.vue'
import WipView from '@/views/WipView.vue'
import RegulatoryReports from '@/views/RegulatoryReports.vue'
import Home from '@/views/Home.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'pagetitle_login',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      title: 'pagetitle_logout',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/password-reset/',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      title: 'pagetitle_password_reset',
    },
  },
  {
    path: '/password-reset-confirm/:uidb64/:token',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
    meta: {
      title: 'pagetitle_password_reset_confirm',
    },
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '/training',
    name: 'Training',
    component: Training,
  },
  {
    path: '/key-steps-and-settings',
    name: 'KeyStepsAndSettings',
    component: KeyStepsAndSettings,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: Faq,
  },
  {
    path: '/quick-start',
    name: 'QuickStart',
    component: QuickStart,
  },
  {
    path: '/hands-on-training',
    name: 'HandsOnTraining',
    component: HandsOnTraining,
  },
  {
    path: '/wip',
    name: 'WipView',
    component: WipView,
  },
  {
    path: '/regulatory-reports',
    name: 'RegulatoryReports',
    component: RegulatoryReports,
  },
  {
    path: '*',
    redirect: { name: 'not-found' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
